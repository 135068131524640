/*!
 * Select2 Bootstrap Theme v0.1.0-beta.10 (https://select2.github.io/select2-bootstrap-theme)
 * Copyright 2015-2017 Florian Kissling and contributors (https://github.com/select2/select2-bootstrap-theme/graphs/contributors)
 * Licensed under MIT (https://github.com/select2/select2-bootstrap-theme/blob/master/LICENSE)
 */

.select2-container--bootstrap {
    display: block;
    /*------------------------------------*      #COMMON STYLES
    \*------------------------------------*/
    /**
     * Search field in the Select2 dropdown.
     */
    /**
     * No outline for all search fields - in the dropdown
     * and inline in multi Select2s.
     */
    /**
     * Adjust Select2's choices hover and selected styles to match
     * Bootstrap 3's default dropdown styles.
     *
     * @see http://getbootstrap.com/components/#dropdowns
     */
    /**
     * Clear the selection.
     */
    /**
     * Address disabled Select2 styles.
     *
     * @see https://select2.github.io/examples.html#disabled
     * @see http://getbootstrap.com/css/#forms-control-disabled
     */
    /*------------------------------------*      #DROPDOWN
    \*------------------------------------*/
    /**
     * Dropdown border color and box-shadow.
     */
    /**
     * Limit the dropdown height.
     */
    /*------------------------------------*      #SINGLE SELECT2
    \*------------------------------------*/
    /*------------------------------------*    #MULTIPLE SELECT2
    \*------------------------------------*/
    /**
     * Address Bootstrap control sizing classes
     *
     * 1. Reset Bootstrap defaults.
     * 2. Adjust the dropdown arrow button icon position.
     *
     * @see http://getbootstrap.com/css/#forms-control-sizes
     */
    /* 1 */
    /*------------------------------------*    #RTL SUPPORT
    \*------------------------------------*/
}

.select2-container--bootstrap .select2-selection {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #f0f0f0;
    border: none;
    border-radius: 0;
    color: #333;
    font-size: 16px;
    outline: 0;
}

.select2-container--bootstrap .select2-selection.form-control {
    border-radius: 0;
}

.select2-container--bootstrap .select2-search--dropdown .select2-search__field {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    color: #333;
    font-size: 16px;
}

.select2-container--bootstrap .select2-search__field {
    outline: 0;
    /* Firefox 18- */
    /**
       * Firefox 19+
       *
       * @see http://stackoverflow.com/questions/24236240/color-for-styled-placeholder-text-is-muted-in-firefox
       */
}

.select2-container--bootstrap .select2-search__field::-webkit-input-placeholder {
    color: #999;
}

.select2-container--bootstrap .select2-search__field:-moz-placeholder {
    color: #999;
}

.select2-container--bootstrap .select2-search__field::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.select2-container--bootstrap .select2-search__field:-ms-input-placeholder {
    color: #999;
}

.select2-container--bootstrap .select2-results__option {
    padding: 6px 12px;
    /**
       * Disabled results.
       *
       * @see https://select2.github.io/examples.html#disabled-results
       */
    /**
       * Hover state.
       */
    /**
       * Selected state.
       */
}

.select2-container--bootstrap .select2-results__option[role=group] {
    padding: 0;
}

.select2-container--bootstrap .select2-results__option[aria-disabled=true] {
    color: #777777;
    cursor: not-allowed;
}

.select2-container--bootstrap .select2-results__option[aria-selected=true] {
    background-color: #f5f5f5;
    color: #262626;
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
    background-color: #337ab7;
    color: #fff;
}

.select2-container--bootstrap .select2-results__option .select2-results__option {
    padding: 6px 12px;
}

.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}

.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -12px;
    padding-left: 24px;
}

.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -24px;
    padding-left: 36px;
}

.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -36px;
    padding-left: 48px;
}

.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -48px;
    padding-left: 60px;
}

.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -60px;
    padding-left: 72px;
}

.select2-container--bootstrap .select2-results__group {
    color: #777777;
    display: block;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    white-space: nowrap;
}

.select2-container--bootstrap.select2-container--focus .select2-selection, .select2-container--bootstrap.select2-container--open .select2-selection {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    border: 1px solid #66afe9;
}

.select2-container--bootstrap.select2-container--open {
    /**
       * Make the dropdown arrow point up while the dropdown is visible.
       */
    /**
       * Handle border radii of the container when the dropdown is showing.
       */
}

.select2-container--bootstrap.select2-container--open .select2-selection .select2-selection__arrow b {
    border-color: transparent transparent #333333 transparent;
    border-width: 0 4px 4px 4px;
}

.select2-container--bootstrap.select2-container--open.select2-container--below .select2-selection {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-color: transparent;
}

.select2-container--bootstrap.select2-container--open.select2-container--above .select2-selection {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top-color: transparent;
}

.select2-container--bootstrap .select2-selection__clear {
    color: #999;
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px;
}

.select2-container--bootstrap .select2-selection__clear:hover {
    color: #333;
}

.select2-container--bootstrap.select2-container--disabled .select2-selection {
    border-color: #ccc;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.select2-container--bootstrap.select2-container--disabled .select2-selection,
.select2-container--bootstrap.select2-container--disabled .select2-search__field {
    cursor: not-allowed;
}

.select2-container--bootstrap.select2-container--disabled .select2-selection,
.select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
    background-color: #eeeeee;
}

.select2-container--bootstrap.select2-container--disabled .select2-selection__clear,
.select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove {
    display: none;
}

.select2-container--bootstrap .select2-dropdown {
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid #66afe9;
    overflow-x: hidden;
    margin-top: -1px;
}

.select2-container--bootstrap .select2-dropdown--above {
    -webkit-box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.175);
    margin-top: 1px;
}

.select2-container--bootstrap .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}

.select2-container--bootstrap .select2-selection--single {
    height: 50px;
    line-height: 2.5em;
    padding: 6px 24px 6px 24px;
    /**
       * Adjust the single Select2's dropdown arrow button appearance.
       */
}

.select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
    position: absolute;
    bottom: 0;
    right: 12px;
    top: 0;
    width: 4px;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
    border-color: #333333 transparent transparent transparent;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    height: 0;
    left: 0;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
    color: #333;
    padding: 0;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--bootstrap .select2-selection--multiple {
    min-height: 34px;
    padding: 0;
    height: auto;
    /**
       * Make Multi Select2's choices match Bootstrap 3's default button styles.
       */
    /**
       * Minus 2px borders.
       */
    /**
       * Clear the selection.
       */
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__rendered {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    line-height: 1.42857143;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    float: left;
    margin-top: 5px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    cursor: default;
    float: left;
    margin: 5px 0 0 6px;
    padding: 0 6px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    background: transparent;
    padding: 0 12px;
    height: 32px;
    line-height: 1.42857143;
    margin-top: 0;
    min-width: 5em;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 3px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #333;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
    margin-top: 6px;
}

.select2-container--bootstrap .select2-selection--single.input-sm,
.input-group-sm .select2-container--bootstrap .select2-selection--single,
.form-group-sm .select2-container--bootstrap .select2-selection--single {
    border-radius: 0;
    font-size: 12px;
    height: 30px;
    line-height: 1.5;
    padding: 5px 22px 5px 10px;
    /* 2 */
}

.select2-container--bootstrap .select2-selection--single.input-sm .select2-selection__arrow b,
.input-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.form-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
    margin-left: -5px;
}

.select2-container--bootstrap .select2-selection--multiple.input-sm,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple,
.form-group-sm .select2-container--bootstrap .select2-selection--multiple {
    min-height: 30px;
    border-radius: 0;
}

.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-selection__choice,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    font-size: 12px;
    line-height: 1.5;
    margin: 4px 0 0 5px;
    padding: 0 5px;
}

.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-search--inline .select2-search__field,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    padding: 0 10px;
    font-size: 12px;
    height: 28px;
    line-height: 1.5;
}

.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-selection__clear,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
    margin-top: 5px;
}

.select2-container--bootstrap .select2-selection--single.input-lg,
.input-group-lg .select2-container--bootstrap .select2-selection--single,
.form-group-lg .select2-container--bootstrap .select2-selection--single {
    border-radius: 0;
    font-size: 18px;
    height: 46px;
    line-height: 1.3333333;
    padding: 10px 31px 10px 16px;
    /* 1 */
}

.select2-container--bootstrap .select2-selection--single.input-lg .select2-selection__arrow,
.input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow,
.form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
    width: 5px;
}

.select2-container--bootstrap .select2-selection--single.input-lg .select2-selection__arrow b,
.input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
    border-width: 5px 5px 0 5px;
    margin-left: -5px;
    margin-left: -10px;
    margin-top: -2.5px;
}

.select2-container--bootstrap .select2-selection--multiple.input-lg,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple,
.form-group-lg .select2-container--bootstrap .select2-selection--multiple {
    min-height: 46px;
    border-radius: 0;
}

.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-selection__choice,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 0;
    margin: 9px 0 0 8px;
    padding: 0 10px;
}

.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-search--inline .select2-search__field,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    padding: 0 16px;
    font-size: 18px;
    height: 44px;
    line-height: 1.3333333;
}

.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-selection__clear,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
    margin-top: 10px;
}

.select2-container--bootstrap .select2-selection.input-lg.select2-container--open .select2-selection--single {
    /**
       * Make the dropdown arrow point up while the dropdown is visible.
       */
}

.select2-container--bootstrap .select2-selection.input-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #999 transparent;
    border-width: 0 5px 5px 5px;
}

.input-group-lg .select2-container--bootstrap .select2-selection.select2-container--open .select2-selection--single {
    /**
       * Make the dropdown arrow point up while the dropdown is visible.
       */
}

.input-group-lg .select2-container--bootstrap .select2-selection.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #999 transparent;
    border-width: 0 5px 5px 5px;
}

.select2-container--bootstrap[dir="rtl"] {
    /**
       * Single Select2
       *
       * 1. Makes sure that .select2-selection__placeholder is positioned
       *    correctly.
       */
    /**
       * Multiple Select2
       */
}

.select2-container--bootstrap[dir="rtl"] .select2-selection--single {
    padding-left: 24px;
    padding-right: 12px;
}

.select2-container--bootstrap[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 0;
    padding-left: 0;
    text-align: right;
    /* 1 */
}

.select2-container--bootstrap[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--bootstrap[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 12px;
    right: auto;
}

.select2-container--bootstrap[dir="rtl"] .select2-selection--single .select2-selection__arrow b {
    margin-left: 0;
}

.select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right;
}

.select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 0;
    margin-right: 6px;
}

.select2-container--bootstrap[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}

/*------------------------------------*  #ADDITIONAL GOODIES
\*------------------------------------*/
/**
 * Address Bootstrap's validation states
 *
 * If a Select2 widget parent has one of Bootstrap's validation state modifier
 * classes, adjust Select2's border colors and focus states accordingly.
 * You may apply said classes to the Select2 dropdown (body > .select2-container)
 * via JavaScript match Bootstraps' to make its styles match.
 *
 * @see http://getbootstrap.com/css/#forms-control-validation
 */
.has-warning .select2-dropdown,
.has-warning .select2-selection {
    border-color: #8a6d3b;
}

.has-warning .select2-container--focus .select2-selection,
.has-warning .select2-container--open .select2-selection {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    border-color: #66512c;
}

.has-warning.select2-drop-active {
    border-color: #66512c;
}

.has-warning.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #66512c;
}

.has-error .select2-dropdown,
.has-error .select2-selection {
    border-color: #a94442;
}

.has-error .select2-container--focus .select2-selection,
.has-error .select2-container--open .select2-selection {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    border-color: #843534;
}

.has-error.select2-drop-active {
    border-color: #843534;
}

.has-error.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #843534;
}

.has-success .select2-dropdown,
.has-success .select2-selection {
    border-color: #3c763d;
}

.has-success .select2-container--focus .select2-selection,
.has-success .select2-container--open .select2-selection {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    border-color: #2b542c;
}

.has-success.select2-drop-active {
    border-color: #2b542c;
}

.has-success.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #2b542c;
}

/**
 * Select2 widgets in Bootstrap Input Groups
 *
 * @see http://getbootstrap.com/components/#input-groups
 * @see https://github.com/twbs/bootstrap/blob/master/less/input-groups.less
 */
/**
 * Reset rounded corners
 */
.input-group > .select2-hidden-accessible:first-child + .select2-container--bootstrap > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:first-child + .select2-container--bootstrap > .selection > .select2-selection.form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection.form-control {
    border-radius: 0;
}

.input-group > .select2-hidden-accessible:not(:first-child):not(:last-child) + .select2-container--bootstrap:last-child > .selection > .select2-selection,
.input-group > .select2-hidden-accessible:not(:first-child):not(:last-child) + .select2-container--bootstrap:last-child > .selection > .select2-selection.form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.input-group > .select2-container--bootstrap {
    display: table;
    table-layout: fixed;
    position: relative;
    z-index: 2;
    width: 100%;
    margin-bottom: 0;
    /**
     * Adjust z-index like Bootstrap does to show the focus-box-shadow
     * above appended buttons in .input-group and .form-group.
     */
    /**
     * Adjust alignment of Bootstrap buttons in Bootstrap Input Groups to address
     * Multi Select2's height which - depending on how many elements have been selected -
     * may grow taller than its initial size.
     *
     * @see http://getbootstrap.com/components/#input-groups
     */
}

.input-group > .select2-container--bootstrap > .selection > .select2-selection.form-control {
    float: none;
}

.input-group > .select2-container--bootstrap.select2-container--open, .input-group > .select2-container--bootstrap.select2-container--focus {
    z-index: 3;
}

.input-group > .select2-container--bootstrap,
.input-group > .select2-container--bootstrap .input-group-btn,
.input-group > .select2-container--bootstrap .input-group-btn .btn {
    vertical-align: top;
}

/**
 * Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
 *
 * Provides `!important` for certain properties of the class applied to the
 * original `<select>` element to hide it.
 *
 * @see https://github.com/select2/select2/pull/3301
 * @see https://github.com/fk/select2/commit/31830c7b32cb3d8e1b12d5b434dee40a6e753ada
 */
.form-control.select2-hidden-accessible {
    position: absolute !important;
    width: 1px !important;
}

/**
 * Display override for inline forms
 */
@media (min-width: 768px) {
    .form-inline .select2-container--bootstrap {
        display: inline-block;
    }
}